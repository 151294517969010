
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import { ReportingViewType } from "../../types/reporting-view";
import AlertsIcon from "../equipment-alerting/AlertsIcon.vue";
import { Equipment } from "../../types/equipment";
import EquipmentBarchart from "../equipment-reporting/EquipmentBarchart.vue";
import {
  convertEnumStatus,
  getClassForStatusText,
} from "../../helpers/equipment-status-helpers";
import { EquipmentComment, QualityKPI } from "../../types/equipment-comment";
import {
  getClassForQualityKpiChip,
  getClassForQualityKpiIcon,
  getQualityKpiChipIcon,
  getQualityKpiIcon,
} from "../../helpers/equipment-comment-helper";
import moment, { MomentInput } from "moment";
import LegendTooltip from "../common/LegendTooltip.vue";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
    equipmentListView: {
      type: Boolean,
      required: false,
    },
    showStats: {
      type: Boolean,
      required: false,
    },
    weeklyComments: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters([
      "getReportingView",
      "getSelectedCountryBloc",
      "getSelectedSite",
      "getEquipmentDetails",
    ]),
  },
  methods: {
    ...mapActions([
      "displayEditCommentPopin",
      "updateEquipmentCommentDetailsState",
      "updateEquipmentDetails",
      "equipmentCommentDetailsLoaded",
    ]),
    iconARType(item: number) {
      if (item > 99) {
        return "arrow_upward";
      } else {
        return "arrow_downward";
      }
    },
  },
  components: {
    EquipmentStatusIcon,
    AlertsIcon,
    LegendTooltip,
    EquipmentBarchart,
  },
  data() {
    return {
      iconType: "expand_more",
      showTooltip: false,
    };
  },
  name: "EquipmentTag",
})
export default class EquipmentTag extends Vue {
  ReportingViewType = ReportingViewType;
  getReportingView!: ReportingViewType;
  iconType!: string;
  equipment!: Equipment;
  showBarChart!: boolean;
  getSelectedSite!: any;
  showTooltip!: boolean;
  displayEditCommentPopin!: ({
    displayEditCommentPopin,
    isEditPopinReadOnly,
  }: any) => void;
  updateEquipmentCommentDetailsState!: (
    equipmentComment: EquipmentComment
  ) => void;
  updateEquipmentDetails!: (equipment: Equipment) => Promise<void>;
  equipmentCommentDetailsLoaded!: ({
    equipmentId,
    equipmentCommentId,
  }: {
    equipmentId: number;
    equipmentCommentId: number | undefined;
  }) => Promise<void>;
  getEquipmentDetails!: Equipment;

  get reportingView(): ReportingViewType {
    return this.getReportingView;
  }

  convertEnumToStatus(status: string): string {
    return convertEnumStatus(status);
  }

  formatDate(date: MomentInput): string {
    return moment(date).format("YYYY/MM/DD HH:mm");
  }

  getClass(): any {
    return getClassForStatusText(this.equipment?.lastEquipmentStatus?.status);
  }

  getClassQualityIcon(qualityKpi: QualityKPI | undefined): any {
    return {
      ...getClassForQualityKpiIcon(qualityKpi),
      iconQuality: true,
    };
  }

  qualityIcon(qualityKpi: QualityKPI | undefined): string {
    return getQualityKpiIcon(qualityKpi);
  }

  get icon(): string {
    return getQualityKpiChipIcon(QualityKPI.GOOD);
  }

  getClassQualityIconForAvlRel(
    availability: number | undefined,
    reliability: number | undefined
  ): any {
    if (availability != undefined && reliability != undefined) {
      if (availability > 95 && reliability > 97) {
        return getClassForQualityKpiChip(QualityKPI.GOOD);
      }
    }
    if (availability != undefined) {
      if (availability > 95) return getClassForQualityKpiChip(QualityKPI.FAIR);
    }
    if (reliability != undefined) {
      if (reliability > 97) return getClassForQualityKpiChip(QualityKPI.FAIR);
    }
    return getClassForQualityKpiChip(QualityKPI.CRITICAL);
  }

  geticonForAvlRel(
    availability: number | undefined,
    reliability: number | undefined
  ): string | undefined {
    if (availability != undefined && reliability != undefined) {
      if (availability > 95 && reliability > 97) {
        return getQualityKpiChipIcon(QualityKPI.GOOD);
      }
    }
    if (availability != undefined) {
      if (availability > 95) return getQualityKpiChipIcon(QualityKPI.FAIR);
    }
    if (reliability != undefined) {
      if (reliability > 97) return getQualityKpiChipIcon(QualityKPI.FAIR);
    }
    return getQualityKpiChipIcon(QualityKPI.CRITICAL);
  }

  formatter(val: any, isMTBFMTTR: boolean): string {
    if (val === -1) return "-";
    if (isMTBFMTTR) {
      if (val) return parseInt(val).toString();
      else return "-";
    } else {
      if (val) return val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      else return "-";
    }
  }

  async onEditComment(equipmentComment: EquipmentComment): Promise<void> {
    this.$q.loading.show();
    await this.updateEquipmentDetails(this.equipment);
    await this.equipmentCommentDetailsLoaded({
      equipmentId: this.getEquipmentDetails.id,
      equipmentCommentId: equipmentComment.id,
    });
    this.$q.loading.hide();
    this.displayEditCommentPopin({
      displayEditCommentPopin: true,
      isEditPopinReadOnly: false,
    });
  }

  emitExpensionButtonTag(): void {
    this.iconType =
      this.iconType === "expand_more" ? "expand_less" : "expand_more";
  }

  getBgColor(
    availability: number | undefined,
    reliability: number | undefined
  ): string | undefined {
    if (availability != undefined && reliability != undefined) {
      if (availability > 95 && reliability > 97) {
        return "lightgreen";
      }
    }
    if (availability != undefined) {
      if (availability > 95) return "lightsalmon";
    }
    if (reliability != undefined) {
      if (reliability > 97) return "lightsalmon";
    }
    return "lightcoral";
  }

  showSerialNumber(): boolean {
    return (
      this.equipment.driver === "Turbine" &&
      (this.equipment.technology === "Aeroderivative" ||
        this.equipment.technology === "Industrial")
    );
  }
  openPopup() {
    this.showTooltip = true;
  }
}
