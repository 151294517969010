import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-422d29a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row no-wrap" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_td = _resolveComponent("q-td")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_td, {
      ref: "",
      key: "woNumber",
      style: {"width":"5%"}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(_ctx.params.value), 1 /* TEXT */),
          _createElementVNode("a", {
            class: "main-link",
            target: "_blank",
            href: `https://azrpscefappgwst.scef.iasp.tgscloud.net/tracking-for-wo?country=${_ctx.getSelectedCountryBlocInShort}&${encodeURIComponent(
            'WO n°'
          )}=${_ctx.params.value}`
          }, [
            _createVNode(_component_q_icon, {
              class: "icon",
              size: "xs",
              name: "launch"
            })
          ], 8 /* PROPS */, _hoisted_2)
        ])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ]))
}