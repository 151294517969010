
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { ref, unref } from "vue";
import AddEquipmentCommentPopin from "./AddEquipmentCommentPopin.vue";
import EditEquipmentCommentPopin from "./EditEquipmentCommentPopin.vue";
import { EquipmentComment } from "@/types/equipment-comment";
import {
  convertEnumStatus,
  getClassForStatusText,
  formatDate,
  equipmentStatuses,
} from "../../helpers/equipment-status-helpers";
import { convertStatusEventEnum } from "../../types/status-events";
import { Equipment } from "../../types/equipment";
import EditMenu from "./EditMenu.vue";
import ConfirmDialog from "../common/ConfirmDialog.vue";
import { EquipmentCommentFile } from "../../types/equipment-comment-file";
import EquipmentStatusIcon from "../equipment-status/EquipmentStatusIcon.vue";
import {
  EquipmentStatus,
  EquipmentStatuses,
} from "../../types/equipment-status";
import * as _ from "lodash";
import moment, { MomentInput } from "moment";
import {
  getClassForQualityKpiChip,
  getQualityKpiChipIcon,
  convertEnumInformationType,
  convertEnumAnalysis,
  getInformationTypesForFilter,
} from "../../helpers/equipment-comment-helper";
import QualityKpi from "./QualityKpi.vue";
import { InformationType, QualityKPI } from "../../types/equipment-comment";
import { deleteFiles, downloadFile } from "../../helpers/file-upload-helper";
import { UserRole } from "../../types/user-info";
import {
  isUserUnknown,
  isUserGuest,
  isUserAdmin,
} from "../../helpers/user-role-helper";
import { EquipmentSystem } from "../../types/equipment-model";
import {
  EquipmentDetails,
  EquipmentParamDetails,
  Trade,
} from "@/types/equipment-tree";
import {
  findEquipmentDetails,
  getShowEquipmentHierarchy,
  getTrade,
} from "@/helpers/equipment-condition-helper";
import EquipmentApi from "../../api/equipment";
import {
  getMessageByStatusCode,
  isSuccess,
  treatErrors,
} from "../../helpers/http-status-helper";

@Options({
  props: {
    equipment: {
      type: Object,
      required: false,
    },
    statusIdFilter: {
      type: Number,
      required: false,
    },
  },
  methods: {
    ...mapActions([
      "displayAddCommentPopin",
      "claimSasToken",
      "displayEditCommentPopin",
      "deleteEquipmentComment",
      "updateIsCommentPopinReadonly",
      "updateEquipmentCommentDetailsState",
      "displayHttpErrorDialog",
    ]),
    formatDate(date: MomentInput) {
      return moment(date).format("YYYY/MM/DD HH:mm");
    },
  },
  computed: {
    ...mapGetters([
      "getSasToken",
      "getEquipmentDetails",
      "getAuthInfoAPI",
      "getUserRole",
      "getSelectedCountryBloc",
      "inputValue",
      "getEquipmentParam",
      "getFlaringUnit",
      "getProductionShortfallUnit",
      "getWaterInjectShortfallUnit",
      "getSelectedCountryBlocInShort",
      "getSelectedSite",
    ]),
  },
  components: {
    AddEquipmentCommentPopin,
    EditEquipmentCommentPopin,
    EditMenu,
    ConfirmDialog,
    EquipmentStatusIcon,
    QualityKpi,
  },
  data() {
    return {
      typeFilter: "",
      equipmentStatuses,
      equipmentStatusFilter: "",
      authorFilter: "",
      hideAutomaticCommentsFilter: true,
    };
  },
  name: "EventsCard",
})
export default class EventsCard extends Vue {
  getSelectedSite!: string;
  displayAddCommentPopin!: (displayAddCommentPopin: boolean) => void;
  displayEditCommentPopin!: ({
    displayEditCommentPopin,
    isEditPopinReadOnly,
  }: any) => void;
  displayHttpErrorDialog!: ({
    displayHttpErrorDialog,
    httpErrorMessage,
  }: {
    displayHttpErrorDialog: boolean;
    httpErrorMessage: string;
  }) => void;
  filter = ref("");
  claimSasToken!: () => Promise<void>;
  getSasToken!: string;
  getEquipmentDetails!: Equipment;
  displayConfirmDeleteCommentDialog = false;
  currentComment!: EquipmentComment | undefined;
  informationType = InformationType;
  getUserRole!: UserRole;
  typeFilter!: InformationType | string | undefined;
  equipmentStatusFilter!: EquipmentStatuses | string | undefined;
  statusIdFilter!: number | undefined;
  equipmentSystemEnum = EquipmentSystem;
  equipment!: Equipment;
  authorFilter!: string | undefined;
  getSelectedCountryBloc!: any;
  hideAutomaticCommentsFilter!: boolean;
  getEquipmentParam!: EquipmentParamDetails;

  getInformationTypesForFilter(): any[] {
    return getInformationTypesForFilter(
      this.getEquipmentDetails?.equipmentModel?.system
    );
  }

  updateEquipmentCommentDetailsState!: (
    equipmentComment: EquipmentComment
  ) => void;
  pagination = {
    rowsPerPage: 5,
  };
  columnsStatuses = [
    {
      name: "date",
      label: "Date - Time (Status)",
      align: "left",
      field: "date",
      sortable: true,
    },
  ];
  columnsComments = [
    {
      name: "date",
      label: "Date - Time",
      align: "left",
      field: "date",
    },
    {
      name: "comment",
      align: "left",
      label: "Comment",
      field: "comment",
    },
    {
      name: "informationType",
      align: "left",
      label: "Comment type",
      field: "informationType",
    },
    {
      name: "qualityKpi",
      align: "left",
      label: "KPI",
      field: "qualityKpi",
    },
    {
      name: "woNumber",
      align: "left",
      label: "WO number",
      field: "woNumber",
    },
    {
      name: "woStatus",
      align: "left",
      label: "WO status",
      field: "woStatus",
    },
    {
      name: "actions",
      align: "center",
      label: "",
      field: "actions",
    },
    {
      name: "expand",
      align: "left",
      label: "",
    },
  ];

  deleteEquipmentComment!: ({
    equipmentComment,
    equipmentId,
  }: {
    equipmentComment: EquipmentComment | undefined;
    equipmentId: number;
  }) => Promise<boolean>;

  async downloadFile(filename: string): Promise<void> {
    await this.claimSasToken();
    const token = this.getSasToken;
    if (token) {
      await downloadFile(token, "file-upload", filename);
    }
  }

  async editEquipmentComment(
    equipmentComment: EquipmentComment
  ): Promise<void> {
    this.updateEquipmentCommentDetailsState(equipmentComment);
    this.displayEditCommentPopin({
      displayEditCommentPopin: true,
      isEditPopinReadOnly: false,
    });
  }

  get authors(): string[] {
    const allAuthors = _.map(
      this.equipment.equipmentComments,
      (equipmentComment: EquipmentComment) => {
        return unref(equipmentComment.author);
      }
    );
    return _.compact(_.uniq(allAuthors));
  }

  commentsByStatuses(equipment: Equipment): any {
    let equipmentStatuses: any;
    if (equipment.equipmentStatuses?.length) {
      equipmentStatuses = _.sortBy(equipment.equipmentStatuses, "date");
    } else {
      equipmentStatuses = [
        {
          comment: null,
          date: "1700-01-01T08:05:00.000Z",
          equipmentDetailsId: null,
          equipmentMajorFailure: null,
          event: null,
          fireConfirmed: null,
          flaring: null,
          gasOrOilLeak: null,
          id: null,
          productionShortfall: null,
          status: null,
          tradeTypeId: null,
          waterInjectShortfall: null,
        },
      ];
    }
    let commentsByStatuses = [];
    if (this.statusIdFilter) {
      let nextIndexIfFilteredByStatus = -1;
      const equipmentStatusesFiltered = equipmentStatuses.filter(
        (equipmentStatus: EquipmentStatus, index: number) => {
          if (equipmentStatus.id === this.statusIdFilter)
            nextIndexIfFilteredByStatus = index + 1;
          return equipmentStatus.id === this.statusIdFilter;
        }
      );
      const nextDate = equipmentStatuses[nextIndexIfFilteredByStatus]
        ? equipmentStatuses[nextIndexIfFilteredByStatus].date
        : new Date();

      const equipmentComments = this.filterCommentsForStatus(
        equipment,
        equipmentStatusesFiltered[0],
        nextDate
      );
      commentsByStatuses = [
        {
          status: equipmentStatusesFiltered[0],
          comments: _.orderBy(equipmentComments, "date", "desc"),
          statusEndDate: nextDate,
        },
      ];
    } else {
      commentsByStatuses = _.map(
        equipmentStatuses,
        (status: EquipmentStatus, index: number) => {
          const nextStatus = equipmentStatuses[index + 1];
          let nextStatusDate = nextStatus ? nextStatus.date : new Date();

          const equipmentComments = this.filterCommentsForStatus(
            equipment,
            status,
            nextStatusDate
          );
          return {
            status: status,
            comments: _.orderBy(equipmentComments, "date", "desc"),
            statusEndDate: nextStatusDate,
          };
        }
      );
    }
    return _.orderBy(
      commentsByStatuses.filter(
        (commentByStatus) => commentByStatus.comments.length > 0
      ),
      "status.date",
      "desc"
    );
  }

  async exportEDExcel(): Promise<void> {
    this.$q.loading.show();
    try {
      const res = await EquipmentApi.exportEDExcelData(
        this.getEquipmentDetails.id,
        this.getSelectedCountryBloc,
        this.getSelectedSite
      );
      this.$q.loading.hide();
      if (isSuccess(res.status)) {
        console.log("res.stauts", res.status);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          `OneCBM-EquipmentDetails-${this.getSelectedCountryBloc}-${this.getSelectedSite}-${this.getEquipmentDetails.id}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (err: any) {
      this.$q.loading.hide();
      this.displayHttpErrorDialog({
        displayHttpErrorDialog: true,
        httpErrorMessage:
          "Export report statistics: " +
          getMessageByStatusCode(treatErrors(err)),
      });
    }
  }

  filterCommentsForStatus(
    equipment: Equipment,
    status: EquipmentStatus,
    nextStatusDate: Date | string
  ): EquipmentComment[] | undefined {
    if (this.typeFilter && this.typeFilter === "serial_number") {
      return equipment.equipmentComments?.filter((comment: any) => {
        return (
          new Date(comment.date) >= new Date(status?.date) &&
          new Date(comment.date) < new Date(nextStatusDate) &&
          status?.serialNumber !== null &&
          (!this.equipmentStatusFilter ||
            this.equipmentStatusFilter === status?.status) &&
          (!this.authorFilter || this.authorFilter === unref(comment.author)) &&
          (!this.hideAutomaticCommentsFilter ||
            unref(comment.author) !== "OneCBM")
        );
      });
    } else {
      return equipment.equipmentComments?.filter((comment: any) => {
        return (
          new Date(comment.date) >= new Date(status?.date) &&
          new Date(comment.date) < new Date(nextStatusDate) &&
          (!this.typeFilter || this.typeFilter === comment.informationType) &&
          (!this.equipmentStatusFilter ||
            this.equipmentStatusFilter === status?.status) &&
          (!this.authorFilter || this.authorFilter === unref(comment.author)) &&
          (!this.hideAutomaticCommentsFilter ||
            unref(comment.author) !== "OneCBM")
        );
      });
    }
  }

  generateSeperatorText(
    status: EquipmentStatus,
    statusEndDate: string
  ): string {
    return `${convertEnumStatus(status.status)} - From ${formatDate(
      status.date
    )} to ${formatDate(statusEndDate)} - ${
      convertStatusEventEnum(status.event)
        ? convertStatusEventEnum(status.event)
        : "Unknown Event"
    }`;
  }

  getClass(comment: EquipmentComment): any {
    return getClassForStatusText(comment?.status?.status);
  }

  async openConfirmDeleteComment(comment: EquipmentComment): Promise<void> {
    this.currentComment = comment;
    this.displayConfirmDeleteCommentDialog = true;
  }

  closeConfirmDeleteComment(): void {
    this.displayConfirmDeleteCommentDialog = false;
  }

  async onConfirmDeleteComment(): Promise<void> {
    if (this.currentComment) {
      if (this.currentComment.equipmentCommentFiles.length > 0) {
        await this.claimSasToken();
        const token = this.getSasToken;
        if (token) {
          await deleteFiles(
            token,
            _.map(
              this.currentComment.equipmentCommentFiles,
              (file: EquipmentCommentFile) => {
                return file.name;
              }
            )
          );
        }
      }

      const isDeleted = await this.deleteEquipmentComment({
        equipmentComment: this.currentComment,
        equipmentId: this.getEquipmentDetails.id,
      });
      if (isDeleted) {
        this.showNotifCommentDeleted();
      }
    }

    this.closeConfirmDeleteComment();
  }

  get confirmDeleteCommentMessage(): string {
    return "Do you confirm to delete the comment and all the file(s) associated ?";
  }

  showNotifCommentDeleted(): void {
    const equipment = this.getEquipmentDetails;
    this.$q.notify({
      message: `Comment for ${equipment.equipmentModel.name} ${
        equipment.tag ? equipment.tag : ""
      } has been deleted.`,
      color: "positive",
      textColor: "black",
      actions: [
        {
          icon: "close",
          color: "black",
        },
      ],
    });
  }

  getInformationTypeLabel(informationType: string): string {
    return convertEnumInformationType(informationType);
  }

  getAnalysisTypeLabel(analysisType: string): string {
    return convertEnumAnalysis(analysisType);
  }

  getClassQualityIcon(qualityKpi: QualityKPI): string {
    return getClassForQualityKpiChip(qualityKpi);
  }

  icon(qualityKpi: QualityKPI): string {
    return getQualityKpiChipIcon(qualityKpi);
  }

  isUserUnknownOrGuest(): boolean {
    return isUserUnknown(this.getUserRole) || isUserGuest(this.getUserRole);
  }

  isUserAdmin(): boolean {
    return isUserAdmin(this.getUserRole);
  }

  resetFilters(): void {
    this.filter = ref("");
    this.typeFilter = undefined;
    this.equipmentStatusFilter = undefined;
    this.authorFilter = undefined;
    this.$emit("onFilterReset");
  }

  tradeValue(id: number | undefined): Trade | undefined {
    return getTrade(id, this.getEquipmentParam);
  }

  showEquipmentHierarchy(id: number | undefined): string {
    return getShowEquipmentHierarchy(id, this.getEquipmentParam);
  }

  equipmentDetails(id: number | undefined): EquipmentDetails | undefined {
    return findEquipmentDetails(id, this.getEquipmentParam);
  }
}
