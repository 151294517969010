import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dbe3dac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "equipment-list q-pa-md" }
const _hoisted_2 = { class: "row justify-center q-px-xs" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row justify-between q-mb-md items-center" }
const _hoisted_5 = { class: "row justify-center" }
const _hoisted_6 = {
  key: 0,
  class: "col-12"
}
const _hoisted_7 = { class: "row q-ml-sm" }
const _hoisted_8 = {
  key: 1,
  class: "col-12 row"
}
const _hoisted_9 = { class: "q-ml-sm" }
const _hoisted_10 = { class: "equipment text-h6 float-bottom subtitle" }
const _hoisted_11 = {
  "data-test": "equipment-model-name",
  class: "equipment float-bottom title q-mt-sm"
}
const _hoisted_12 = { class: "row q-ml-lg" }
const _hoisted_13 = {
  class: "q-pa-sm",
  style: {"border":"2px solid rgb(203, 216, 221) !important","border-radius":"8px","background-color":"rgb(235, 241, 243)"}
}
const _hoisted_14 = { "data-test": "equipment-tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_EquipmentSelectorFilter = _resolveComponent("EquipmentSelectorFilter")!
  const _component_SectorSelectorFilter = _resolveComponent("SectorSelectorFilter")!
  const _component_StatusSelectorFilter = _resolveComponent("StatusSelectorFilter")!
  const _component_StatusChangeFromFilter = _resolveComponent("StatusChangeFromFilter")!
  const _component_EquipmentsWithAlertsFilter = _resolveComponent("EquipmentsWithAlertsFilter")!
  const _component_StatusBoxFilter = _resolveComponent("StatusBoxFilter")!
  const _component_CriticalFilter = _resolveComponent("CriticalFilter")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_EquipmentModel = _resolveComponent("EquipmentModel")!
  const _component_EquipmentTag = _resolveComponent("EquipmentTag")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_EquipmentStatusIcon = _resolveComponent("EquipmentStatusIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_separator = _resolveComponent("q-separator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PageTitle, {
      title: "Equipment health status",
      page: _ctx.pageEnum.EquipmentList
    }, null, 8 /* PROPS */, ["page"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_EquipmentSelectorFilter),
            _createVNode(_component_SectorSelectorFilter),
            _createVNode(_component_StatusSelectorFilter),
            _createVNode(_component_StatusChangeFromFilter),
            _createVNode(_component_EquipmentsWithAlertsFilter),
            _createVNode(_component_StatusBoxFilter),
            _createVNode(_component_CriticalFilter),
            _createVNode(_component_q_btn, {
              outline: "",
              rounded: "",
              "no-caps": "",
              label: "Reset filters",
              class: "main-button reset-filters",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetFilters()))
            }),
            _createVNode(_component_q_btn, {
              outline: "",
              rounded: "",
              "no-caps": "",
              label: _ctx.expandButtonLabel,
              class: "main-button reset-filters expand-all",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openOrCloseAllExpansionItems()))
            }, null, 8 /* PROPS */, ["label"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.getStatusCheckboxFilter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipmentsGroupedBySystemAndModelAndDesc, (equipmentsGroupedBySystem, system) => {
                return (_openBlock(), _createElementBlock("div", { key: system }, [
                  _createVNode(_component_q_expansion_item, {
                    modelValue: _ctx.expansion_items['collapsible-item-' + system],
                    "onUpdate:modelValue": [
                      ($event: any) => ((_ctx.expansion_items['collapsible-item-' + system]) = $event),
                      _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkIfAllExpansionItemsAreOpened()))
                    ],
                    class: "q-mtb-lg container expand-equipment overflow-hidden",
                    label: system,
                    "expand-icon-class": "expansion-icon",
                    "data-test": "equipment-system",
                    "default-opened": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentsGroupedBySystem, (equipmentGroupedByModel, modelNameDesc) => {
                            return (_openBlock(), _createElementBlock("div", { key: modelNameDesc }, [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_EquipmentModel, {
                                  "model-name": 
                        equipmentGroupedByModel[0].equipmentModel.name
                      ,
                                  description: equipmentGroupedByModel[0].name_description
                                }, null, 8 /* PROPS */, ["model-name", "description"])
                              ]),
                              _createVNode(_component_q_card_section, { class: "equipment-line" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentGroupedByModel, (equipment) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: equipment.id
                                    }, [
                                      _createVNode(_component_EquipmentTag, {
                                        equipment: equipment,
                                        "equipment-list-view": true
                                      }, null, 8 /* PROPS */, ["equipment"])
                                    ]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "label"])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.getStatusCheckboxFilter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipmentsGroupedBySystemAndModelAndDesc, (equipmentsGroupedBySystem, system) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: system,
                  class: "systemStyle q-pr-md"
                }, [
                  _createVNode(_component_q_expansion_item, {
                    modelValue: _ctx.expansion_items['collapsible-item-' + system],
                    "onUpdate:modelValue": [
                      ($event: any) => ((_ctx.expansion_items['collapsible-item-' + system]) = $event),
                      _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkIfAllExpansionItemsAreOpened()))
                    ],
                    class: "q-mtb-lg container expand-equipment overflow-hidden",
                    label: system,
                    "expand-icon-class": "expansion-icon",
                    "data-test": "equipment-system",
                    style: {"background-color":"rgb(235, 235, 235, 0.5)"},
                    "default-opened": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentsGroupedBySystem, (equipmentGroupedByModel, modelNameDesc) => {
                            return (_openBlock(), _createElementBlock("div", { key: modelNameDesc }, [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_q_card_section, { class: "col" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_10, _toDisplayString(equipmentGroupedByModel[0].name_description), 1 /* TEXT */),
                                    _createElementVNode("div", _hoisted_11, _toDisplayString(equipmentGroupedByModel[0].equipmentModel.name), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              _createElementVNode("div", _hoisted_12, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipmentGroupedByModel, (equipment) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: equipment.id,
                                    class: "q-mr-sm q-mb-sm"
                                  }, [
                                    _createVNode(_component_router_link, {
                                      to: {
                          name: 'EquipmentDetails',
                          params: {
                            id: equipment.id,
                            equipmentModelName: equipment.equipmentModel.name,
                            equipmentTag: equipment.tag,
                            countryBloc: _ctx.getSelectedCountryBloc,
                            site: _ctx.getSelectedSite,
                          },
                        },
                                      style: {"text-decoration":"none","color":"black"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_13, [
                                          _createElementVNode("b", _hoisted_14, _toDisplayString(equipment.tag), 1 /* TEXT */),
                                          _createVNode(_component_EquipmentStatusIcon, {
                                            class: "q-ml-sm",
                                            status: 
                              equipment.lastEquipmentStatus &&
                              equipment.lastEquipmentStatus.status
                            
                                          }, null, 8 /* PROPS */, ["status"])
                                        ])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                                  ]))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]),
                              _createVNode(_component_q_separator)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "label"])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}