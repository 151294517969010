import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-387a5f55"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "title" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EquipmentTitle = _resolveComponent("EquipmentTitle")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["subheader", _ctx.page === _ctx.pageEnum.EquipmentDetail ? 'equipDetails-head' : ''])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */),
      (_ctx.page === _ctx.pageEnum.EquipmentDetail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_EquipmentTitle, { equipment: _ctx.getEquipmentDetails }, null, 8 /* PROPS */, ["equipment"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 2 /* CLASS */))
}