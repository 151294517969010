import moment from "moment-timezone";
import { EquipmentStatus, EquipmentStatuses } from "../types/equipment-status";
import { StatusEventEnum } from "@/types/status-events";
import { Equipment } from "@/types/equipment";
import _ from "lodash";

export enum StatusIcon {
  unscheduled = "clear",
  scheduled = "build",
  run_limited = "priority_high",
  run = "done",
  abnr = "schedule",
  missing = "",
}
export enum StatusColor {
  unscheduled = "red",
  scheduled = "orange",
  run_limited = "green-5",
  run = "green-5",
  abnr = "grey-6",
  missing = "",
}

export const equipmentStatuses = [
  {
    label: "UNSCH",
    value: EquipmentStatuses.UNSCH,
  },
  {
    label: "SCH",
    value: EquipmentStatuses.SCH,
  },
  {
    label: "ABNR",
    value: EquipmentStatuses.ABNR,
  },
  {
    label: "RUN - Limited",
    value: EquipmentStatuses.RUN_LIMITED,
  },
  {
    label: "RUN",
    value: EquipmentStatuses.RUN,
  },
];

export const equipmentStatusesNew = [
  {
    label: "UNSCH",
    value: EquipmentStatuses.UNSCH,
  },
  {
    label: "SCH",
    value: EquipmentStatuses.SCH,
  },
  {
    label: "ABNR",
    value: EquipmentStatuses.ABNR,
  },
  {
    label: "RUN - Limited",
    value: EquipmentStatuses.RUN_LIMITED,
  },
  {
    label: "RUN",
    value: EquipmentStatuses.RUN,
  },
];

export const allEvents = [
  {
    label: "Unplanned shutdown due to external process, event",
    value: StatusEventEnum.UNSCH_ENV,
    associatedStatus: EquipmentStatuses.UNSCH,
  },
  {
    label: "Unplanned shutdown due to human error",
    value: StatusEventEnum.UNSCH_HUMAN,
    associatedStatus: EquipmentStatuses.UNSCH,
  },
  {
    label: "Unplanned shutdown due to package failure / issue",
    value: StatusEventEnum.UNSCH_FAILURE,
    associatedStatus: EquipmentStatuses.UNSCH,
  },
  {
    label: "Planned shutdown for Control system upgrade",
    value: StatusEventEnum.SCH_UPGRADE,
    associatedStatus: EquipmentStatuses.SCH,
  },
  {
    label: "Planned shutdown for Minor PM (up to yearly PM), Fire and Gas PM",
    value: StatusEventEnum.SCH_MINOR_PM,
    associatedStatus: EquipmentStatuses.SCH,
  },
  {
    label: "Planned shutdown for Major PM (overhaul / standard exchange)",
    value: StatusEventEnum.SCH_MAJOR_PM,
    associatedStatus: EquipmentStatuses.SCH,
  },
  {
    label: "Planned shutdown for Major non routine: structure, enclosure",
    value: StatusEventEnum.SCH_NON_ROUTINE,
    associatedStatus: EquipmentStatuses.SCH,
  },
  {
    label: "Planned shutdown for On Condition Maintenance",
    value: StatusEventEnum.SCH_CONDITION_MAINT,
    associatedStatus: EquipmentStatuses.SCH,
  },
  {
    label: "Shutdown for stand-by (= Available But Not Running)",
    value: StatusEventEnum.ABNR_STAND_BY,
    associatedStatus: EquipmentStatuses.ABNR,
  },
  {
    label: "Running in downgraded mode",
    value: StatusEventEnum.RUN_LIMITED,
    associatedStatus: EquipmentStatuses.RUN_LIMITED,
  },
  {
    label: "Running",
    value: StatusEventEnum.RUN_RESTARTED,
    associatedStatus: EquipmentStatuses.RUN,
  },
];

export function convertEnumStatus(status: string | undefined): string {
  switch (status) {
    case "unscheduled":
      return "UNSCH";
    case "scheduled":
      return "SCH";
    case "run_limited":
      return "RUN LIMITED";
    case "run":
      return "RUN";
    case "abnr":
      return "ABNR";
    default:
      return "";
  }
}

export function getLegend(status: string | undefined): string {
  switch (status) {
    case "unscheduled":
      return "Unscheduled maintenance";
    case "scheduled":
      return "Scheduled maintenance";
    case "run_limited":
      return "Running with limitations";
    case "run":
      return "Running";
    case "abnr":
      return "Available but not running";
    case "work_order":
      return "Work orders not technically completed";
    case "spare_parts":
      return "Missing spare parts";
    case "alert":
      return "Anomalies detected";
    default:
      return "";
  }
}

export function formatDate(date?: Date | string): string {
  if (date) {
    const currentTimeZone = moment.tz.guess();
    return moment.tz(date, currentTimeZone).format("YYYY/MM/DD HH:mm");
  }
  return "";
}

export function formatDateWithoutHours(date?: Date | string): string {
  if (date) {
    const currentTimeZone = moment.tz.guess();
    return moment.tz(date, currentTimeZone).format("YYYY/MM/DD");
  }
  return "";
}

export function getClassForStatusText(
  status: EquipmentStatuses | undefined
): any {
  if (status) {
    return {
      red: status === EquipmentStatuses.UNSCH,
      orange: status === EquipmentStatuses.SCH,
      green:
        status === EquipmentStatuses.RUN_LIMITED ||
        status === EquipmentStatuses.RUN,
      grey: status === EquipmentStatuses.ABNR,
      "q-ml-sm": true,
      "text-weight-bold": true,
    };
  }
}

export function getLastSerialNumber(getEquipmentDetails: Equipment): {
  serialNumber: string | undefined | null;
  installationDate: string | undefined | null;
} {
  const statusFiltered = _.orderBy(
    _.filter(
      getEquipmentDetails?.equipmentStatuses,
      (status: EquipmentStatus) => {
        return status.installationDate != null && status.serialNumber != null;
      }
    ),
    "installationDate",
    "desc"
  );
  if (statusFiltered.length > 0) {
    const latestStatus = statusFiltered[0];
    return {
      serialNumber: latestStatus.serialNumber,
      installationDate: latestStatus.installationDate,
    };
  } else {
    return {
      serialNumber: null,
      installationDate: null,
    };
  }
}
