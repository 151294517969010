
import { Equipment } from "../../types/equipment";
import moment from "moment-timezone";
import _ from "lodash";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";

@Options({
  name: "Datepicker",
  props: {
    isHistoricalStatus: {
      type: Boolean,
      required: false,
    },
    isEditMode: {
      type: Boolean,
      required: false,
    },
    defaultYearMonth: {
      type: String,
      required: false,
    },
  },
  methods: {
    ...mapActions(["updateSelectedDate"]),
  },
  computed: {
    ...mapGetters(["getDateToUpdate", "getEquipmentDetails"]),
  },
})
export default class Datepicker extends Vue {
  updateSelectedDate!: (date: string | undefined) => void;
  getDateToUpdate!: string;
  getEquipmentDetails!: Equipment;
  isHistoricalStatus!: boolean;
  isEditMode!: boolean;

  minDate(date: string): boolean {
    const lastStatusDate = this.getEquipmentDetails?.lastEquipmentStatus?.date;
    const momentLastStatusDate = moment(lastStatusDate).set({
      hours: 0,
      minutes: 0,
    });
    const momentDate = moment(date, "YYYY-MM-DD").add(1, "days");
    if (
      this.isHistoricalStatus ||
      (this.isHistoricalStatus && this.isEditMode)
    ) {
      return momentDate.isBefore(moment().add(1, "days"));
      // } else if (this.isHistoricalStatus) {
      //   return momentDate.isSameOrBefore(momentLastStatusDate.add(1, "days"));
    } else {
      if (lastStatusDate) {
        return (
          momentDate.isAfter(momentLastStatusDate) &&
          momentDate.isBefore(moment().add(1, "days"))
        );
      } else return momentDate.isBefore(moment().add(1, "days"));
    }
  }

  minTime(hour: number, minute: number): boolean {
    const lastStatusDate = this.getEquipmentDetails?.lastEquipmentStatus?.date;
    const momentLastStatusDate = moment.tz(lastStatusDate, moment.tz.guess());
    const momentSelectedDate = moment(this.selectedDate, "YYYY/MM/DD");
    const now = moment(new Date(), "YYYY/MM/DD HH:mm");
    const lastStatusDateIsToday = now.isSame(momentSelectedDate, "day");
    if (momentLastStatusDate.isSame(momentSelectedDate, "day")) {
      if (!this.isHistoricalStatus) {
        const lastStatusHour = momentLastStatusDate.hour();
        const lastStatusMinute = momentLastStatusDate.minute();
        const nowHour = now.hour();
        const nowMinute = now.minute();

        if (lastStatusDateIsToday) {
          if (minute === null) {
            return this.isHourBetweenLastStatusTimeAndToday(
              hour,
              lastStatusHour,
              nowHour
            );
          }
          return this.isTimeBetweenLastStatusTimeAndToday(
            hour,
            minute,
            lastStatusHour,
            nowHour,
            lastStatusMinute,
            nowMinute
          );
        }
        return this.isTimeSuperiorToLastStatusTime(
          hour,
          minute,
          lastStatusHour,
          lastStatusMinute
        );
      }
      momentSelectedDate.set({ hour, minute });
      return momentSelectedDate.isBefore(momentLastStatusDate);
    }
    if (lastStatusDateIsToday) {
      momentSelectedDate.set({ hour, minute });
      return momentSelectedDate.isBefore(moment());
    }
    return true;
  }

  isHourBetweenLastStatusTimeAndToday(
    hour: number,
    lastStatusHour: number,
    nowHour: number
  ): boolean {
    return hour >= lastStatusHour && hour <= nowHour;
  }

  isTimeBetweenLastStatusTimeAndToday(
    hour: number,
    minute: number,
    lastStatusHour: number,
    nowHour: number,
    lastStatusMinute: number,
    nowMinute: number
  ): boolean {
    return (
      (hour === lastStatusHour && minute > lastStatusMinute) ||
      (hour === nowHour && minute <= nowMinute) ||
      (hour !== lastStatusHour && hour !== nowHour)
    );
  }

  isTimeSuperiorToLastStatusTime(
    hour: number,
    minute: number,
    lastStatusHour: number,
    lastStatusMinute: number
  ): boolean {
    return (
      (hour === lastStatusHour && minute > lastStatusMinute) ||
      (hour >= lastStatusHour && minute === null) ||
      (hour !== lastStatusHour && minute != null)
    );
  }

  isValidDate(date: string) {
    if (_.isEmpty(date)) return false;
    const momentDate = moment(date);
    return momentDate.isValid();
  }

  set selectedDate(date: string | undefined) {
    this.updateSelectedDate(date);
  }

  get selectedDate(): string | undefined {
    return this.getDateToUpdate;
  }
}
